<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-4">Webshop</h1>

      <ValidationObserver ref="form" slim>
        <b-card class="mb-3">
          <h3 class="fs-5">Insluiten</h3>
          <p class="text-muted mb-2">Gebruik de onderstaande code om de webshop op je website te integreren.</p>
          <div class="p-2 bg-dark">
            <code class="text-white">
             &lt;iframe id="tixgo" src="{{ widget }}"&gt;&lt;/iframe&gt;&lt;script src="https://shop.tixgo.nl/embed.js"&gt;&lt;/script&gt;
            </code>
          </div>
          <hr/>
          <h3 class="fs-5">Linken</h3>
          <p class="text-muted mb-2">Wil je direct linken naar de webshop? Gebruik dan de onderstaande link.</p>
          <b-form-input v-model="link" readonly />
        </b-card>

        <b-card class="mb-3">
          <h3 class="fs-5">Winkel</h3>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Shop link</label>
              <b-input-group prepend="https://shop.tixgo.nl/">
                <b-form-input v-model="form.slug" />
              </b-input-group>
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider v-slot="{ errors }">
            <div class="mb-3">
              <label>Inloggen met</label>
              <b-form-select v-model="form.signin_methods" :options="signinMethodOptions" multiple />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">Betaalmethodes</label>
              <b-form-select v-model="form.payment_methods" :options="paymentMethodOptions" multiple />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>

          <div class="mb-3">
            <label>Algemene voorwaarden</label>
            <p class="text-muted mb-2">Deze algemene voorwaarden worden getoond in het bestelproces.</p>
            <b-form-input v-model="form.terms_conditions" placeholder="https://mijnwebsite.nl/algemene-voorwaarden" />
          </div>

          <div class="mb-3">
            <label>Privacy statement</label>
            <p class="text-muted mb-2">Dit privacy statement worden getoond in het bestelproces.</p>
            <b-form-input v-model="form.privacy_statement" placeholder="https://mijnwebsite.nl/privacy-statement" />
          </div>
        </b-card>

        <b-card class="mb-3">
          <h3 class="fs-5">Thema</h3>

          <div class="mb-3">
            <label>Primaire kleur</label>
            <b-form-input v-model="form.primary_color" />
          </div>

          <div class="mb-3">
            <label>Achtergrond kleur</label>
            <b-form-input v-model="form.background_color" />
          </div>

          <b-row>
            <b-col md="6">
              <div class="mb-3">
                <label>Header</label>
                <p class="text-muted">Optimaal formaat: 1920x1080 / 4:3 ratio (max. 5MB)</p>
                <DropFile v-model="form.header_image" accept="image/*" @change="fileChange" @delete="fileChange" />
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-3">
                <label>Logo</label>
                <p class="text-muted">Optimaal formaat: 1024x1024 / 1:1 ratio (max. 5MB)</p>
                <DropFile v-model="form.header_logo" accept="image/*" @change="fileChange"  @delete="fileChange" />
              </div>
            </b-col>
          </b-row>

          <div class="mb-3">
            <label>Eigen CSS</label>
            <b-form-textarea v-model="form.custom_css" />
          </div>

        </b-card>
      </ValidationObserver>

      <div class="clearfix">
        <b-button @click="saveWebshop" variant="primary" class="float-right">
          Opslaan
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>
      </div>

    </b-container>
  </div>
</template>

<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'
  
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import DropFile from "@/components/DropFile.vue";

  export default {
      metaInfo: {
          title: 'Webshop',
      },
      components: {
        DropFile,
        ValidationObserver,
        ValidationProvider,
      },
      data() {
          return {
            form: {
              slug: null,
              signin_methods: [],
              payment_methods: [],
              terms_conditions: null,
              privacy_statement: null,
              primary_color: null,
              background_color: null,
              header_image: null,
              header_logo: null,
              custom_css: null,
            },
            link: '',
            widget: '',
            paymentMethodOptions: [
              { value: 'ideal', html:' iDeal' },
              { value: 'apple-pay', html: 'Apple Pay' },
              { value: 'credit-debitcard', html: 'Krediet- en debetkaarten' },
              { value: 'transfer', html: 'Overboeking' },
              { value: 'bancontact', html: 'Bancontact <small>(België)</small>' },
              { value: 'sofort', html: 'SOFORT <small>(Duitsland en 7 andere landen)</small>' },
              { value: 'belfius', html: 'Belfius Direct Net <small>(België)</small>' },
              { value: 'giropay', html: 'Giropay <small>(Duitsland)</small>' },
            ],
            signinMethodOptions: [
              { value: 'linkedin', html:' LinkedIn' },
              { value: 'microsoft', html: 'Microsoft' },
              { value: 'google', html: 'Google' },
              { value: 'apple', html: 'Apple' },
              { value: 'facebook', html: 'Facebook' },
              { value: 'instagram', html: 'Instagram' },
              { value: 'twitter', html: 'Twitter' },
              { value: 'spotify', html: 'Spotify' },
            ]
          }
      },
      methods: {
        ...mapActions(useShopStore, ['setLoading']),
        fileChange: function() {
          this.$axios.put("https://api.tixgo.nl/webshops/update", this.form)
              .then( response => {
                response;
          });
        },
        getOrganisation: function() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/organisations/show")
              .then( response => {
                this.form = response.data;
                this.link = 'https://shop.tixgo.nl/' + this.form.slug + '-' + this.form.slug_hash;
                this.widget = 'https://shop.tixgo.nl/widget/' + this.form.slug + '-' + this.form.slug_hash;
                this.setLoading(false);
          });
        },
        async saveWebshop() {

          let check = await this.$refs['form'].validate();

          if (!check) {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            
            return;
          }

          this.$axios.put("https://api.tixgo.nl/webshops/update", this.form)
              .then( response => {
                response;
              this.$router.push('/manage/organisation');
          });
        }
      },
      created() {
        this.getOrganisation();
      }
  }
</script>